<template>
	<div class="animated fadeIn">
		<div
			v-if="!$route.params.distributorId"
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr v-if="!$route.params.distributorId">
		<data-filter
			v-if="!$route.params.distributorId"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						v-if="admin.includes($user.details().type)"
						class="col-sm">
						<search
							v-model="filters.userId"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:default-params="{role:'distributor,customer,affiliate'}"
							class="mb-6"
							placeholder="autocomplete_placeholder"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm col-md-6">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div
					v-if="false && dateRange === dateRanges.custom"
					class="row mb-4">
					<div class="col">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div class="col">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="border-top-0 pointer"
							@click="sortByField('user_id')">
							{{ translate('user_id') }}
							<sort field="user_id" />
						</th>
						<th
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('product')">
							{{ translate('product') }}
							<sort field="product" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('remaining_usages')">
							{{ translate('remaining_downloads') }}
							<sort field="remaining_usages" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('created_at')">
							{{ translate('purchase_date') }}
							<sort field="created_at" />
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="align-middle">
							<span
								v-if="item.attributes.user_type === distributor"
								class="pointer badge badge-primary"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.product_code_name) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.remaining_usages }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.purchase_date.date).format(dateFormat) }}
						</td>
						<td
							class="align-middle text-center">
							<b-button
								v-if="showDownloadButton(item.attributes.remaining_usages)"
								v-b-tooltip.hover
								:title="translate('download')"
								variant="primary"
								class="bg-primary-alt"
								@click="download(item.id, item.attributes.product_code_name)">
								<i
									class="fas fa-download"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { Grids, Products } from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { MMMDY_FORMAT, DATE_RANGES as dateRanges, V_CALENDAR_CONFIG } from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Downloads from '@/util/Order';
import { SEARCH_USERS } from '@/config/endpoint';
import { admin, distributor } from '@/settings/Roles';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'Downloads',
	messages: [Grids, Products],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			downloads: new Downloads(),
			alert: new this.$Alert(),
			dateFormat: MMMDY_FORMAT,
			dateRanges,
			endpoint,
			calendarConfig: V_CALENDAR_CONFIG,
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			try {
				return this.downloads.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.downloads.data.errors.errors;
		},
		pagination() {
			return this.downloads.data.pagination;
		},
		data() {
			try {
				const { data } = this.downloads.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			try {
				return !!this.data.length;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		this.getDownloads();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			let userId = this.$user.details().id;
			if (this.$route.params.distributorId) {
				userId = this.$route.params.distributorId;
			}
			this.downloads.getDownloads(userId, options);
		},
		showDownloadButton(usages) {
			return this.$can('downloads', 'use')
				&& (usages > 0 || admin.includes(this.$user.details().type));
		},
		download(itemId, itemCode) {
			const config = {
				allowEscapeKey: false,
				allowOutsideClick: false,
			};

			const loading = new this.$Alert();
			loading.loading(this.translate('loading_title'), this.translate('loading_text'), config);

			this.downloads.download(itemId).then((response) => {
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.download = itemCode;
				link.click();
				loading.close();
				this.getDownloads();
			}).catch(() => {
				loading.close();
				this.getDownloads();
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		getDownloads() {
			let userId = this.$user.details().id;
			if (this.$route.params.distributorId) {
				userId = this.$route.params.distributorId;
			}
			this.downloads.getDownloads(userId);
		},
	},
};
</script>
